@charset "UTF-8";
/*!
 * Main Stylesheet for Footmall.se | Footmall AB | Copyright 2013 Alexander Cederblad
 */
::selection {
  background: black;
  color: #fcfcfc;
}

::-moz-selection {
  background: black;
  color: #fcfcfc;
}

::-webkit-input-placeholder {
  color: #2b2b2b;
}

::-moz-placeholder {
  color: #2b2b2b;
}

html {
  font-size: 100%;
  overflow-x: hidden;
  width: 100%;
}

body {
  cursor: default;
  font-size: 14px;
  overflow-x: hidden;
}

div,
nav,
section,
input,
input[type=search],
select,
option,
textarea,
img,
ul,
ol {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  color: #2b2b2b;
}

h1 {
  font-size: 1.2em;
}

address,
p {
  line-height: 180%;
}

a {
  color: #FD9259;
  text-decoration: none;
}

a:hover {
  color: #feb38b;
}

strong {
  color: #2b2b2b;
}

.fm-teal-link {
  color: #57c7b3 !important;
}

.fm-teal-link:hover {
  color: #7dd4c4 !important;
}

.fm-sale-link {
  color: #ff685c !important;
}

.fm-sale-link:hover {
  color: #ff978f !important;
}

.fm-vintage-link {
  color: #e78042 !important;
}

.fm-vintage-link:hover {
  color: #ed9e6f !important;
}

.external-link:after {
  content: '\2192';
  margin-left: .2em;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 1px solid #d9d9d9;
}

input,
select,
option,
textarea {
  background: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: none;
  border-radius: 3px;
  padding: 0.8em 1em;
  outline: 0;
}

input[type=checkbox],
input[type=radio] {
  cursor: pointer;
}

label {
  color: #2b2b2b;
  display: inline-block;
  font-weight: bold;
  margin: 1em 0 .5em 0;
}

.label-radio {
  cursor: pointer;
  font-weight: normal;
  margin: 0;
}

fieldset > .label-radio {
  border-bottom: 1px solid #d9d9d9;
  display: block;
  padding: 1em;
}

fieldset > .label-radio:last-of-type {
  border-bottom: 0;
}

input[type=radio] {
  margin-right: .15em;
}

.input-ios-fixed {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  opacity: 1 !important;
}

.form-disabled {
  opacity: .5;
}

.form-disabled * {
  cursor: not-allowed;
}

ul,
ol {
  list-style: none;
  padding: 0;
}

table {
  max-width: 100%;
}

th {
  text-align: left;
}

hr {
  border: 0;
  border-top: 1px solid #d9d9d9;
  margin: 20px 0;
}

.items-content {
  display: flex;
  flex-wrap: wrap;
}

.row-items {
  display: flex;
  flex-wrap: wrap;
}

.wrapper-row {
  width: 1227px;
  margin-left: auto;
  margin-right: auto;
}

.wrapper-row::after {
  clear: both;
  content: "";
  display: table;
}

.row-items {
  flex: 0 0 1010px;
}

.row-items > h1,
.row-items > h2,
.row-items > hr {
  margin-left: 12px;
}

.row-items > hr {
  margin-top: 2.4em;
  margin-bottom: 2.4em;
}

.row-full {
  margin: 4em 0;
  padding: 3em 0;
  background: #57c7b3;
  color: white;
}

.row-full .onesixth {
  width: 195px;
}

.row-full .onesixth:nth-child(6n+1) {
  margin-left: 0;
}

.row-new {
  float: left;
  margin-left: 12px;
  width: 998px;
}

.row-new::after {
  clear: both;
  content: "";
  display: table;
}

.row-new-border {
  border-radius: 3px;
  border: 1px solid #d9d9d9;
}

.row-new-header {
  background: #cccccc;
}

.onehalf {
  flex: 0 0 48%;
  margin-right: 2%;
  max-width: 48%;
}

.onesixth {
  flex: 0 0 190px;
  margin: 0 0 12px 12px;
  background: white;
  border-radius: 3px;
  max-width: 190px;
}

.sidebar-main {
  width: 205px;
  float: left;
  margin-right: 10px;
}

.col-full,
.col-half,
.col-quarter,
.col-three-quarters {
  box-sizing: border-box;
  float: left;
  padding-left: 12px;
  padding-right: 12px;
}

.col-full {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-half {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-quarter {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-three-quarters {
  flex: 0 0 75%;
  max-width: 75%;
}

@media all and (max-width: 830px) {
  .col-quarter {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-three-quarters {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.item-full {
  display: flex;
  flex-wrap: wrap;
}

.item-full > h2, .item-full > h3, .item-full > h4 {
  flex: 0 0 100%;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.gray {
  color: #777777;
}

.normal {
  font-weight: normal;
}

.upperspaced {
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0;
}

.clearfix::after, .fm-dropdown-content-row::after, .single-input-group::after {
  clear: both;
  content: "";
  display: table;
}

.no-wrap {
  white-space: nowrap;
}

.hidden {
  display: none !important;
}

.capsule {
  border-radius: 999999px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}

.em-link {
  color: #777777;
  font-size: .85em;
  font-weight: bold;
  margin-right: 1em;
  text-decoration: underline;
  text-transform: uppercase;
}

.em-link:hover {
  color: #5e5d5d;
}

.inactive-link {
  color: #bcbcbc !important;
}

.inactive-link:hover {
  color: #d6d5d5 !important;
}

.gray-link, .info-nav a, #breadcrumb a, .heading-onesixth a, .influencer-attribution a {
  color: #777777;
}

.gray-link:hover, .info-nav a:hover, #breadcrumb a:hover, .heading-onesixth a:hover, .influencer-attribution a:hover {
  color: #919090;
}

.responsive-image, .item-image-container img {
  max-width: 100%;
  height: auto;
  width: auto\9;
}

button,
.btn {
  border-radius: 2.4em;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background: none transparent;
  background-color: #FD9259;
  border: 1px solid #FD9259;
  box-sizing: border-box;
  font-weight: bold;
  line-height: normal;
  outline: none;
  font-size: 0.8em;
  padding: .8em 2em;
  display: inline-block;
}

button:hover,
.btn:hover {
  background-color: #fd9f6d;
  color: white;
}

button:active,
.btn:active {
  background-color: #FD9259;
}

button[disabled=disabled],
.btn[disabled=disabled] {
  opacity: .8;
  cursor: not-allowed;
}

.btn-teal {
  background-color: #57c7b3;
  border: 1px solid #57c7b3;
}

.btn-teal:hover {
  background-color: #66ccba;
}

.btn-teal:active {
  background-color: #57c7b3;
}

.btn-sale {
  background-color: #ff685c;
  border: 1px solid #ff685c;
}

.btn-sale:hover {
  background-color: #ff7b70;
}

.btn-sale:active {
  background-color: #ff685c;
}

.btn-transparent {
  background-color: transparent;
  border: 1px solid #d9d9d9;
  color: #777777;
}

.btn-transparent:active, .btn-transparent:hover {
  background-color: transparent;
  border: 1px solid #2b2b2b;
  color: #2b2b2b;
}

.active .btn-transparent {
  background-color: transparent;
  border: 1px solid #2b2b2b;
  color: #2b2b2b;
}

.btn-white {
  background-color: white;
  color: #2b2b2b;
  border: 1px solid #d9d9d9;
}

.btn-white:hover {
  background-color: white;
  color: #2b2b2b;
  border: 1px solid #2b2b2b;
}

.btn-white:active {
  background-color: white;
  color: #2b2b2b;
}

.btn-gray {
  background-color: #777777;
  border: 1px solid #777777;
}

.btn-gray:hover {
  background-color: #a4a4a4;
}

.btn-gray:active {
  background-color: #9f9f9f;
}

.btn-small {
  padding: .5em 1.2em;
}

.btn-large {
  font-size: 1.2em;
}

.btn-wide {
  width: 100%;
  text-align: center;
  display: block;
}

.btn-icon {
  background-repeat: no-repeat;
  background-position: 1.2em center;
  padding-left: 3.5em;
}

.btn-buy {
  background-image: url(../../gfx/shop.png);
  background-size: 18px;
}

.btn-fav {
  background-image: url(../../gfx/favourite.svg);
  background-size: 18px;
}

.btn-alert {
  background-image: url(../../gfx/alert.svg);
  background-size: 18px;
}

button > small {
  display: block;
}

.btn-dropdown {
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 13px;
}

.btn-dropdown:after {
  content: "\25be";
  padding-left: .85em;
}

.fm-dropdown-open .btn-dropdown:after {
  content: "\25b4";
  padding-left: .85em;
}

.btn-wide.btn-dropdown {
  text-align: left;
}

.btn-wide.btn-dropdown:after {
  float: right;
}

.btn-dropdown-input {
  text-transform: initial;
  border: 1px solid #d9d9d9;
  font-size: 1em;
  font-weight: normal;
  border-radius: 3px;
  line-height: normal;
  padding: .85em 1em;
  color: #2b2b2b;
}

.btn-dropdown-input:hover {
  background: #d9d9d9;
}

.btn-switch {
  border-radius: 1em;
  background: #d9d9d9;
  width: 1.5em;
  height: 1em;
  border: 0;
  display: inline-block;
  outline: none;
  position: relative;
  padding: 0;
  transition: background-color .1s;
}

.btn-switch:after {
  content: '';
  position: absolute;
  background: white;
  width: 0.8em;
  height: 0.8em;
  top: 0.1em;
  left: 0.1em;
  right: auto;
  border-radius: 1em;
}

.btn-switch.active {
  background: #57c7b3;
}

.btn-switch.active:after {
  right: 5%;
  left: auto;
}

.btn-divider {
  color: #777777;
  font-style: italic;
  margin: 0 1em;
}

.lar-pagination {
  color: #FD9259;
  font-size: 25px;
}

.fm-ul-nav li, .filter-ordinary-list li {
  margin: .15em 0;
  position: relative;
}

.fm-ul-nav li a, .filter-ordinary-list li a {
  box-sizing: border-box;
  display: inline-block;
  padding-left: 1.5em;
  position: relative;
  width: 100%;
}

.fm-ul-nav li:before, .filter-ordinary-list li:before {
  background-color: white;
  border: 4px solid white;
  border-radius: 50%;
  box-shadow: #777777 0 0 0 1px;
  content: '';
  height: .3em;
  left: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: .3em;
}

.fm-ul-nav li:hover:before, .filter-ordinary-list li:hover:before, .fm-ul-nav li.current:before, .filter-ordinary-list li.current:before {
  box-shadow: #FD9259 0 0 0 1px;
}

.fm-ul-nav li .active:before, .filter-ordinary-list li .active:before, .fm-ul-nav li.active:before, .filter-ordinary-list li.active:before {
  background-color: #FD9259;
  box-shadow: #FD9259 0 0 0 1px;
}

.fm-ul-nav li .active a, .filter-ordinary-list li .active a, .fm-ul-nav li.active a, .filter-ordinary-list li.active a {
  color: black;
}

.fm-ul-nav li small, .filter-ordinary-list li small {
  display: block;
  color: #d9d9d9;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: .5em;
}

.fm-ul-nav-bg {
  background-color: #ff685c;
  padding: .85em 24px;
  margin: 0 -1px;
}

.fm-ul-nav-bg li a {
  color: white;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-size: .85em;
}

.fm-ul-nav-bg li:before {
  background-color: transparent;
  border: 3px solid transparent;
  box-shadow: none;
  box-shadow: white 0 0 0 2px;
  top: 50%;
  transform: translateY(-50%);
}

.fm-ul-nav-bg li:hover a, .fm-ul-nav-bg li.current a,
.fm-ul-nav-bg li .active a, .fm-ul-nav-bg li.active a {
  color: white;
}

.fm-ul-nav-bg li:hover:before, .fm-ul-nav-bg li.current:before,
.fm-ul-nav-bg li .active:before, .fm-ul-nav-bg li.active:before {
  background-color: white;
  box-shadow: white 0 0 0 2px;
  border: 3px solid #ff685c;
}

.fm-ul-nav-bg-orange {
  background-color: #FD9259;
}

.fm-ul-nav-bg-orange li:hover:before, .fm-ul-nav-bg-orange li.current:before,
.fm-ul-nav-bg-orange li .active:before, .fm-ul-nav-bg-orange li.active:before {
  border: 3px solid #FD9259;
}

.fm-ul-nav-teal li:hover:before, .filter-ordinary-list-teal li:hover:before, .fm-ul-nav-teal li.current:before, .filter-ordinary-list-teal li.current:before {
  box-shadow: #57c7b3 0 0 0 1px;
}

.fm-ul-nav-teal li .active:before, .filter-ordinary-list-teal li .active:before, .fm-ul-nav-teal li.active:before, .filter-ordinary-list-teal li.active:before {
  background-color: #57c7b3;
  box-shadow: #57c7b3 0 0 0 1px;
}

.fm-ul-nav-cb li:before {
  border-radius: 1px;
}

.meta-list li {
  background-position: left center;
  background-repeat: no-repeat;
  color: #777777;
  padding: 0.5em 0 0.5em 2em;
}

.meta-list li.meta-list-desc {
  background-image: url(../../gfx/list.png);
}

.meta-list li.meta-list-size {
  background-image: url(../../gfx/ruler.png);
}

.meta-list li.meta-list-image {
  background-image: url(../../gfx/camera.png);
}

.meta-list li.meta-list-shipping {
  background-image: url(../../gfx/truck.png);
}

.meta-list li.meta-list-delivery {
  background-image: url(../../gfx/calendar.png);
}

.meta-list li.meta-list-pros-orange {
  background-image: url(../../gfx/pros-orange.png);
}

.meta-list li.meta-list-pros-green {
  background-image: url(../../gfx/pros-green.png);
}

.fm-table {
  margin-bottom: 24px;
  font-size: .85em;
  width: 100%;
}

.fm-table th {
  background-color: #fcfcfc;
  border-bottom: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  color: #777777;
  padding: .85em;
  text-transform: uppercase;
}

.fm-table th:last-of-type {
  text-align: right;
}

.fm-table td {
  border-bottom: 1px solid #d9d9d9;
  padding: 0 .85em;
}

.fm-dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.sidebar .fm-dropdown {
  width: 100%;
}

.fm-dropdown-content {
  display: none;
  background: white;
  border-radius: 2.4em;
  border: 1px solid #d9d9d9;
  border-top: 0;
  margin-top: -1px;
  text-align: left;
  width: 100%;
}

.fm-dropdown-content h4 {
  display: inline-block;
  margin: 10px 20px 0 20px;
  font-size: 0.85em;
}

.fm-dropdown-content input {
  background-color: transparent;
  margin: 1px;
  border: 0;
}

.fm-dropdown-content p {
  font-size: .85em;
  font-style: italic;
  text-align: left;
}

.fm-dropdown-content-absolute {
  border: 1px solid #d9d9d9;
  left: 0;
  margin: 3px 0 0 0;
  position: absolute;
  z-index: 90;
}

.fm-dropdown-input {
  width: 100%;
}

.fm-dropdown-absolute {
  min-width: 160px;
}

.fm-dropdown-absolute button,
.fm-dropdown-absolute input {
  width: 100%;
}

.fm-dropdown-absolute .fm-dropdown-content {
  position: absolute;
  z-index: 100;
}

.fm-dropdown-open .fm-dropdown-content {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1.2em;
  -webkit-border-bottom-left-radius: 1.2em;
  -moz-border-radius-bottomright: 1.2em;
  -moz-border-radius-bottomleft: 1.2em;
  border-bottom-right-radius: 1.2em;
  border-bottom-left-radius: 1.2em;
  border-top: 0;
}

.fm-dropdown-open > input,
.fm-dropdown-open > button {
  background-color: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  -webkit-border-top-left-radius: 1.2em;
  -webkit-border-top-right-radius: 1.2em;
  -moz-border-radius-topleft: 1.2em;
  -moz-border-radius-topright: 1.2em;
  border-top-left-radius: 1.2em;
  border-top-right-radius: 1.2em;
  border-bottom: 0;
  color: #777777;
  margin-bottom: 1px;
}

.fm-dropdown-open > input:hover,
.fm-dropdown-open > button:hover {
  color: #5e5d5d;
  border-color: #d9d9d9;
  border-bottom: 0;
  background-color: transparent;
}

.fm-input-dropdown-open .fm-dropdown-content {
  display: block;
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1.2em;
  -webkit-border-bottom-left-radius: 1.2em;
  -moz-border-radius-bottomright: 1.2em;
  -moz-border-radius-bottomleft: 1.2em;
  border-bottom-right-radius: 1.2em;
  border-bottom-left-radius: 1.2em;
  border-top: 0;
}

.fm-input-dropdown-open input,
.fm-input-dropdown-open button {
  background-color: transparent;
  border-radius: 0;
  -webkit-border-top-left-radius: 1.2em;
  -webkit-border-top-right-radius: 1.2em;
  -moz-border-radius-topleft: 1.2em;
  -moz-border-radius-topright: 1.2em;
  border-top-left-radius: 1.2em;
  border-top-right-radius: 1.2em;
  border-bottom: 0;
  margin-bottom: 1px;
}

.fm-input-dropdown-open + .header-favs .banner-icon-eye,
.fm-input-dropdown-open + .header-favs .banner-icon-fav {
  display: none;
}

.fm-input-dropdown-open + .header-favs .banner-icon-close {
  display: inline-block;
}

.fm-dropdown-content-row {
  margin: .85em 20px;
}

.fm-form input[type=text], .fm-form input[type=password], .fm-form textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.fm-form input[type=submit] {
  margin-top: 1em;
}

.single-input-group input {
  float: left;
}

.single-input-group input[type=text], .single-input-group input[type=search], .single-input-group input[type=file] {
  width: 75%;
}

.single-input-group input[type=submit] {
  margin-left: 5%;
  width: 20%;
}

.fm-input {
  background-position: 12px center;
  background-repeat: no-repeat;
  border: 1px solid #d9d9d9;
  border-radius: 2.4em;
  font-style: italic;
  padding: 0.8em 1em;
  width: 100%;
}

.fm-input-borderless {
  border: 0;
}

.fm-input-icon {
  padding-left: 2.8em;
}

.fm-input-icon-magnifier {
  background-image: url(../../gfx/search.svg);
  background-size: 1.6em;
}

.tappa {
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.tappa.hover {
  background-color: #fcfcfc;
}

.tappa.active {
  background-color: #FD9259;
}

.tappa-message {
  color: #2b2b2b;
  font-size: .85em;
  font-style: italic;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0 1em;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999999;
}

.tappa-wrapper {
  box-shadow: 0 0 0 20px #fcfcfc;
  box-sizing: content-box;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  margin: 1em auto 44px auto;
  padding: 10px;
  position: relative;
}

.tappa-wrapper [type=file] {
  bottom: 0;
  cursor: pointer;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.link-badge {
  border: 1px solid #57c7b3;
  color: #57c7b3;
  font-size: 0.75em;
  border-radius: 2px;
  margin-left: 0.5em;
  padding: 0 0.2em;
}

.jumbotron {
  margin-bottom: 24px;
}

.jumbotron h1 {
  font-size: 3em;
  color: #57c7b3;
}

.jumbotron p {
  padding: 0 20%;
  font-size: 1.2em;
  margin-bottom: 2em;
}

@media all and (max-width: 830px) {
  .jumbotron h1 {
    font-size: 2em;
  }
  .jumbotron p {
    font-size: 1em;
    padding: 0 24px;
  }
}

.message {
  border: 1px solid #d9d9d9;
  background: #fcfcfc;
  padding: 1em;
  margin-top: 1em;
  font-weight: bold;
  color: white;
  border-radius: 3px;
}

.message.green {
  background: #00c166;
  border-color: #005b30;
}

.message.red {
  background: #ff2746;
  border-color: #f30023;
}

.sidebar .message {
  border: 0;
  border-radius: 0;
  font-weight: normal;
  margin-left: 0;
  margin-right: 0;
}

.up-btn {
  background: #57c7b3 url(../../gfx/up.png) no-repeat center center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  bottom: 0;
  cursor: pointer;
  display: none;
  height: 48px;
  opacity: 0.9;
  position: fixed;
  right: 24px;
  width: 48px;
  z-index: 9999;
}

.rating-wrapper {
  text-align: center;
  padding: 9px 0;
}

.rating-stars {
  margin: 0 auto;
  padding: 0;
  width: 105px;
}

.rating-stars::after {
  clear: both;
  content: "";
  display: table;
}

.rating-stars:hover li.active {
  background-image: url(../../gfx/star.png);
}

.rating-stars:hover li.hover {
  background-image: url(../../gfx/star-active.png);
}

.rating-stars li {
  background-image: url(../../gfx/star.png);
  background-repeat: no-repeat;
  float: left;
}

.rating-stars li:hover, .rating-stars li.active, .rating-stars li.hover {
  background-image: url(../../gfx/star-active.png);
}

.rating-stars li a {
  float: left;
  width: 19px;
  height: 18px;
  padding: 0 1px;
}

.rating-info {
  font-size: 0.8em;
  margin: 0.5em 0 0 0;
  color: #777777;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal-backdrop {
  display: block;
}

.modal-open .modal,
.modal-open .modal-wrapper {
  display: block;
}

.modal-backdrop {
  position: fixed;
  overflow: auto;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  opacity: 0.5;
  display: none;
  background-color: #000000;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  overflow-y: scroll;
  display: none;
  z-index: 10001;
}

.modal-close {
  background-color: white;
  background-image: url(../../gfx/close.svg);
  background-size: 1.6em;
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -99999px;
  position: absolute;
  top: 1em;
  right: 1em;
  width: 2.4em;
  height: 2.4em;
}

.modal-wrapper {
  background-color: white;
  border-radius: 3px;
  display: none;
  margin: 30px auto;
  overflow: hidden;
  pointer-events: auto;
  position: relative;
  width: 1048px;
  z-index: 10002;
}

.modal-wrapper > div {
  pointer-events: auto;
}

.modal-header {
  margin: 2em;
}

.modal-main {
  margin: 0 2em;
}

@media all and (max-width: 830px) {
  .modal:not(.modal-loading) .modal-wrapper {
    position: initial;
  }
  .fm-modal {
    padding-top: 4em;
  }
  .modal-close {
    background-image: url(../../gfx/arrow-left.svg);
    background-color: #fcfcfc;
    background-position: left 1em center;
    border-bottom: 1px solid #d9d9d9;
    position: fixed;
    text-indent: 0;
    top: 0;
    right: auto;
    width: 100%;
    font-size: 1.2em;
    color: #2b2b2b;
    height: auto;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 1em 1em 1em 2.4em;
    z-index: 10005;
  }
  .modal-close:hover {
    color: #454444;
  }
}

.slide-menu-open {
  overflow: hidden;
}

.slide-menu-open .slide-menu-backdrop {
  display: block;
}

.slide-menu-open .slide-menu-active.slide-menu {
  left: 0;
}

.slide-menu-backdrop {
  position: fixed;
  overflow: auto;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  opacity: 0.5;
  display: none;
  background-color: black;
}

.slide-menu {
  background: white;
  height: 100%;
  max-width: 86%;
  position: fixed;
  top: 0;
  width: 300px;
  z-index: 2000;
  overflow-y: scroll;
  padding-bottom: 1em;
  left: -300px;
  transition: left 0.3s ease;
}

.slide-menu h2,
.slide-menu input {
  display: block;
}

.slide-menu-fixed {
  max-width: 86%;
  position: fixed;
  top: 0;
  width: 300px;
}

.slide-menu-input {
  background-color: #fcfcfc;
  border-radius: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  font-size: 16px;
}

.slide-menu-back {
  background-color: #fcfcfc;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.slide-menu-back a {
  background: url("../../gfx/arrow-left.svg") left 1em center no-repeat;
  background-size: 1.6em;
  color: #2b2b2b;
  position: relative;
  padding: 1em 1em 1em 2.4em;
  font-weight: bold;
  display: block;
  text-transform: uppercase;
}

.slide-menu-level-sub {
  display: none;
}

.slide-menu-header {
  background: url("../../gfx/close.svg") right 1em center no-repeat;
  background-size: 1.6em;
  margin: 0;
  display: none;
  font-size: 1.2em;
  font-weight: normal;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  padding: 1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

@media all and (max-width: 830px) {
  .slide-menu-list-sub.slide-menu-list a {
    padding-left: 2.4em;
  }
  .slide-menu-list {
    margin: 0;
  }
  .slide-menu-list.sidebar-box, .slide-menu-list.filter-content {
    margin: 0;
  }
  .slide-menu-list [data-slide-to], .slide-menu-list .goto-link {
    color: #2b2b2b;
    background: url("../../gfx/arrow-right.svg") right 1em center no-repeat;
    background-size: 1.6em;
  }
  .slide-menu-list [data-slide-to]:hover, .slide-menu-list .goto-link:hover {
    color: #454444;
  }
  .slide-menu-list a {
    display: block;
    letter-spacing: 0.05em;
    padding: 1em;
    font-weight: 400;
  }
  .slide-menu-list li {
    border-bottom: 1px solid #eee;
  }
}

.banner-loose .banner-main {
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  z-index: 9999;
}

.banner-loose .banner-info,
.banner-loose .banner-hot {
  visibility: hidden;
}

.banner-loose .fb-like {
  display: none;
}

.banner {
  border-bottom: 1px solid #dddddd;
}

.banner::after {
  clear: both;
  content: "";
  display: table;
}

.banner p {
  margin: .2em 0;
}

.banner nav {
  padding: 0.85em 0;
  vertical-align: middle;
}

.banner .fb-like {
  margin: 0 0 0 2em;
}

.banner-counters {
  margin: .85em 0;
  float: left;
}

.info-nav {
  float: right;
  text-transform: uppercase;
}

.info-nav a {
  margin-left: 1.4em;
  font-weight: bold;
  vertical-align: middle;
}

.info-nav-toggle {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4.8em;
}

.banner-info {
  font-size: 0.8em;
}

.banner-main {
  background-color: white;
  padding: 1em 0;
}

.banner-icon {
  color: #2b2b2b;
  background-position: center center;
  background-repeat: no-repeat;
  font-weight: bold;
  font-size: .85em;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  line-height: 19px;
  padding-left: 33px;
  background-position: center left;
  margin-left: 1em;
}

.banner-icon:hover {
  color: #2b2b2b;
  opacity: .7;
}

.banner-icon:first-of-type {
  margin-left: 0;
}

.banner-icon-fav {
  background-image: url(../../gfx/favourite.svg);
  background-size: 20px;
  padding-left: 26px;
}

.banner-icon-fav.active {
  background-image: url(../../gfx/favourite-active.svg);
  color: #FD9259 !important;
}

.no-svg .banner-icon-fav {
  background-image: url(../../gfx/favourite.png);
}

.no-svg .banner-icon-fav.active {
  background-image: url(../../gfx/favourite-small-active.png);
}

.banner-icon-eye {
  background-image: url(../../gfx/latest.svg);
  background-size: 24px;
}

.no-svg .banner-icon-eye {
  background-image: url(../../gfx/latest.png);
}

.banner-icon-close {
  background-image: url(../../gfx/close.svg);
  background-size: 20px;
  width: 20px;
  height: 20px;
  padding: 0;
  display: none;
}

.banner-campaign {
  background-color: #FD9259;
  padding: 1em 0;
  text-align: center;
}

.banner-campaign.banner-black-friday {
  color: #f74f4e !important;
  background-color: #000000 !important;
}

.banner-campaign.banner-black-friday p {
  color: #f74f4e !important;
}

.banner-campaign.banner-black-friday .btn {
  color: #f74f4e !important;
  border-color: #f74f4e !important;
}

.banner-campaign.banner-form > .wrapper-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

@media all and (max-width: 500px) {
  .banner-campaign.banner-form > .wrapper-row p {
    margin-left: 0.5em;
  }
}

.banner-campaign.banner-form > .wrapper-row form {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

@media all and (max-width: 500px) {
  .banner-campaign.banner-form > .wrapper-row form input[name='email'] {
    width: 100px;
    margin-left: 0.5em;
  }
  .banner-campaign.banner-form > .wrapper-row form .btn, .banner-campaign.banner-form > .wrapper-row form button {
    margin-left: 0.5em;
  }
}

.banner-campaign.banner-form > .wrapper-row form button[data-id='dismiss'] {
  margin-right: 0.5em;
}

.banner-campaign p {
  font-size: 0.8em;
  display: inline;
  color: #ffffff;
}

.banner-campaign button,
.banner-campaign .btn {
  margin-left: 1em;
  background-color: none;
  border-color: #ffffff;
}

.banner-campaign button[data-id="dismiss"],
.banner-campaign .btn[data-id="dismiss"] {
  border: none;
  padding: 0;
  height: 20px;
  vertical-align: middle;
}

.banner-campaign button[data-id="dismiss"] img,
.banner-campaign .btn[data-id="dismiss"] img {
  max-height: 100%;
}

.banner-form {
  padding-bottom: 0;
}

.banner-form > div > * {
  margin: 0 0 1em 0;
  display: inline-block;
}

.banner-form .message {
  display: block;
  font-size: 0.8em;
}

.banner-form input[type=text] {
  font-size: .8em;
  width: 180px;
  margin-left: 1em;
}

.header-logo {
  width: 15%;
  margin-top: 8px;
  float: left;
  position: relative;
}

.logo-link {
  display: inline-block;
  line-height: 0;
}

.logo {
  width: 173px;
}

.main-search {
  border: 1px solid transparent;
  background-color: transparent;
  font-size: 16px;
}

.fm-input-dropdown-open .main-search {
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}

.fm-input-dropdown-open .filter-main-search {
  box-shadow: 0 6px 0 rgba(0, 0, 0, 0.1);
}

.filter-main-search {
  position: absolute;
  border: 1px solid #d9d9d9;
  padding: 12px;
  border-radius: 3px;
  background: white;
  width: 100%;
  display: none;
  z-index: 9999;
}

.filter-main-search .heading {
  margin: 1em 0 .15em 0;
}

.filter-main-search .heading:before {
  display: none;
}

.filter-main-search a {
  font-weight: normal;
}

.filter-ordinary-list .main-search-regular a {
  background-image: none;
  padding-left: 0;
}

.filter-ordinary-list .main-search-regular a:hover {
  background-image: none;
}

.filter-ordinary-list .main-search-regular span {
  color: #aaaaaa;
  font-style: italic;
}

.header-widgets {
  position: relative;
  width: 20%;
  margin: 0 2.5%;
  float: left;
}

.header-favs {
  width: 10%;
  float: left;
  text-align: center;
  margin: .6em 0;
}

#main-nav {
  width: 50%;
  font-size: 0.85em;
  float: left;
  text-align: right;
}

#main-nav::after {
  clear: both;
  content: "";
  display: table;
}

#main-nav ul {
  margin: 0;
  text-align: center;
  font-weight: bold;
  display: inline-block;
}

#main-nav li {
  font-size: 1.15em;
  margin: 0 0.7em;
  display: inline;
  text-transform: uppercase;
}

#main-nav li:last-of-type {
  margin-right: 0;
}

#main-nav a {
  color: black;
}

#main-nav a:hover {
  color: #666666;
}

#main-nav .main-nav-sale a {
  color: #ff685c;
}

#main-nav .main-nav-sale a:hover {
  color: #ff8076;
}

.breadcrumb-wrapper {
  padding: 1.2em;
}

#breadcrumb {
  margin: 0;
  display: inline-block;
  list-style: none;
}

#breadcrumb::after {
  clear: both;
  content: "";
  display: table;
}

#breadcrumb li {
  float: left;
  font-size: 1em;
  margin-right: 1em;
  color: #999999;
}

#breadcrumb li + li:before {
  content: "/";
  margin-right: 1em;
}

.sidebar {
  border-radius: 3px;
  margin-bottom: 24px;
}

.sidebar .filter-widget:first-of-type h3,
.sidebar > h3:first-of-type {
  border-top: 0;
}

.sidebar h1 {
  font-size: 1em;
  margin: 0;
  position: relative;
  padding: 15px 20px 0 20px;
}

.sidebar h3:not(.breadcrumb-title) {
  margin: 0;
  position: relative;
}

.sidebar h3:not(.breadcrumb-title).sidebar-heading-btn {
  padding-bottom: 15px;
  cursor: pointer;
}

.sidebar h3:not(.breadcrumb-title).sidebar-heading-btn:hover {
  background: #fdfdfd;
}

.sidebar h3:not(.breadcrumb-title).sidebar-heading-btn:active {
  background: #fafafa;
}

.sidebar h3:not(.breadcrumb-title).toggle {
  background: #f8f8f8;
  cursor: pointer;
  border-bottom: 0;
}

.sidebar h3:not(.breadcrumb-title).toggle + ul,
.sidebar h3:not(.breadcrumb-title).toggle + .filter-widget-body {
  display: none;
}

.sidebar h3:not(.breadcrumb-title).toggle.filter-show {
  border-bottom: 1px solid #d9d9d9;
}

.sidebar h3:not(.breadcrumb-title).toggle.filter-show + ul,
.sidebar h3:not(.breadcrumb-title).toggle.filter-show + .filter-widget-body {
  display: block;
}

.sidebar h3:not(.breadcrumb-title) a {
  display: none;
  margin-top: -.2em;
  margin-left: 0.8em;
  position: absolute;
}

.sidebar h4 {
  margin: 10px 20px 0 20px;
  font-size: 0.85em;
}

.sidebar p {
  font-size: .85em;
  font-style: italic;
}

.sidebar-borderless {
  border: 0;
}

.sidebar-box, .filter-content {
  margin: 1em 20px;
}

.sidebar-box-wide {
  margin: 1em 0;
}

.sidebar-box-input {
  margin: 1em 10px;
  position: relative;
}

#sorting > span {
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 1em;
}

#sorting > div {
  margin-right: 2em;
}

.filter-per-page {
  display: inline;
}

.filter-per-page li {
  display: inline-block;
}

.filter-content {
  background-color: red !important;
  box-shadow: 0 0 2em green !important;
  color: blue !important;
}

.filter-content * {
  background-color: red !important;
  box-shadow: 0 0 2em green !important;
  color: blue !important;
}

.filter-ordinary-list {
  background-color: red !important;
  box-shadow: 0 0 2em green !important;
  color: blue !important;
}

.filter-ordinary-list * {
  background-color: red !important;
  box-shadow: 0 0 2em green !important;
  color: blue !important;
}

.filter-ordinary-list-teal {
  background-color: red !important;
  box-shadow: 0 0 2em green !important;
  color: blue !important;
}

.filter-ordinary-list-teal * {
  background-color: red !important;
  box-shadow: 0 0 2em green !important;
  color: blue !important;
}

#filter-container hr {
  margin: 0;
}

.filter-price-small:before {
  content: '(';
}

.filter-price-small:after {
  content: ')';
}

.filter-size {
  position: relative;
  max-height: 7em;
  overflow-y: auto;
}

.filter-color .inactive {
  opacity: .1;
}

.filter-color .inactive:hover {
  opacity: .1;
}

.filter-color .inactive a {
  cursor: default;
}

.filter-color li {
  border-radius: 50%;
  float: left;
  margin: 0 4px 8px 4px;
  opacity: 0.7;
}

.filter-color li:hover {
  opacity: 1;
}

.filter-color li.active {
  box-shadow: inset 0 0 0 2px white;
  opacity: 1;
}

.filter-color li a {
  float: left;
  height: 16px;
  margin: 0;
  width: 16px;
}

.filter-color .color-black {
  background: black;
  border: 2px solid black;
}

.filter-color .color-black.active {
  background: black;
  border: 2px solid black;
}

.filter-color .color-gray {
  background: #777777;
  border: 2px solid #777777;
}

.filter-color .color-gray.active {
  background: #777777;
  border: 2px solid #777777;
}

.filter-color .color-white {
  background: white;
  border: 2px solid #d9d9d9;
}

.filter-color .color-white.active {
  background: white;
  border: 2px solid #d9d9d9;
  background-color: #fcfcfc;
}

.filter-color .color-brown {
  background: #916b14;
  border: 2px solid #916b14;
}

.filter-color .color-brown.active {
  background: #916b14;
  border: 2px solid #916b14;
}

.filter-color .color-orange {
  background: #FD9259;
  border: 2px solid #FD9259;
}

.filter-color .color-orange.active {
  background: #FD9259;
  border: 2px solid #FD9259;
}

.filter-color .color-yellow {
  background: #fcd932;
  border: 2px solid #fcd932;
}

.filter-color .color-yellow.active {
  background: #fcd932;
  border: 2px solid #fcd932;
}

.filter-color .color-green {
  background: #00c166;
  border: 2px solid #00c166;
}

.filter-color .color-green.active {
  background: #00c166;
  border: 2px solid #00c166;
}

.filter-color .color-blue {
  background: #3b93d8;
  border: 2px solid #3b93d8;
}

.filter-color .color-blue.active {
  background: #3b93d8;
  border: 2px solid #3b93d8;
}

.filter-color .color-pink {
  background: #ff19ce;
  border: 2px solid #ff19ce;
}

.filter-color .color-pink.active {
  background: #ff19ce;
  border: 2px solid #ff19ce;
}

.filter-color .color-purple {
  background: #b500a1;
  border: 2px solid #b500a1;
}

.filter-color .color-purple.active {
  background: #b500a1;
  border: 2px solid #b500a1;
}

.filter-color .color-red {
  background: #f30023;
  border: 2px solid #f30023;
}

.filter-color .color-red.active {
  background: #f30023;
  border: 2px solid #f30023;
}

.filter-color .color-multi {
  background: linear-gradient(0deg, violet 0%, indigo 16%, blue 32%, green 48%, yellow 60%, orange 76%, red 100%);
  border: 2px solid transparent;
}

.filter-color .color-multi:hover {
  opacity: 1;
}

.filter-color .color-multi.active {
  box-shadow: inset 0 0 0 1px white;
  opacity: 1;
}

.filter-brand-input {
  background-color: transparent;
  border: 1px solid transparent;
}

.filter-brand-results {
  border: 0 !important;
}

.filter-reset {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: .8em;
  font-size: 13px;
  text-transform: uppercase;
}

.filter-search-tags {
  margin: 0;
  float: left;
}

.filter-search-tags::after {
  clear: both;
  content: "";
  display: table;
}

.filter-search-tags li {
  float: left;
  margin: 0 0.8em 0.8em 0;
}

.filter-search-tags li:first-of-type {
  color: #2b2b2b;
  font-weight: bold;
  margin-top: .5em;
}

.slide-menu .btn-tag {
  white-space: nowrap;
}

.btn-tag:before {
  margin-right: .5em;
  content: '\2715';
}

.btn-tag[data-type=search] {
  font-style: italic;
}

.btn-tag[data-type=color]:after {
  border: 1px solid transparent;
  content: '';
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -9px;
  pointer-events: none;
  border-radius: 50%;
}

.btn-tag[data-id="black"]:after {
  background: #2b2b2b;
}

.btn-tag[data-id="gray"]:after {
  background: #777777;
}

.btn-tag[data-id="white"]:after {
  background: white;
  border: 1px solid #777777;
}

.btn-tag[data-id="brown"]:after {
  background: #916b14;
}

.btn-tag[data-id="orange"]:after {
  background: #FD9259;
}

.btn-tag[data-id="yellow"]:after {
  background: #fcd932;
}

.btn-tag[data-id="green"]:after {
  background: #00c166;
}

.btn-tag[data-id="blue"]:after {
  background: #3b93d8;
}

.btn-tag[data-id="pink"]:after {
  background: #ff19ce;
}

.btn-tag[data-id="purple"]:after {
  background: #b500a1;
}

.btn-tag[data-id="red"]:after {
  background: #f30023;
}

.btn-tag[data-id="multi"]:after {
  background: linear-gradient(0deg, violet 0%, indigo 16%, blue 32%, green 48%, yellow 60%, orange 76%, red 100%);
}

#filter-container .btn-tag {
  padding: 0.4em 0.8em;
}

#filter-container .btn-tag[data-type="color"] {
  position: absolute;
  padding-right: 2.4em;
}

#filter-container .btn-tag[data-type="color"]:after {
  right: 0.8em;
}

.brand-alphabet {
  border-bottom: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  padding: 1em 0;
  text-align: center;
}

.brand-alphabet li {
  display: inline;
  padding: 1em 0;
}

.brand-alphabet li:hover {
  border-bottom: 1px solid black;
}

.brand-alphabet a {
  padding: .8em;
}

.brand-container {
  border-top: 1px solid #d9d9d9;
  padding: 2em 0;
}

.brand-container h2 {
  float: left;
  width: 10%;
  font-size: 5em;
  margin: 0;
  color: #2b2b2b;
}

.brand-image-container {
  text-align: center;
  margin: 20px 0;
}

.brand-image {
  margin: 0 10px;
  max-height: 50px;
  width: auto;
}

.brand-column {
  list-style: none;
  width: 16%;
  float: left;
  padding: 0 2% 0 0;
  font-size: 0.85em;
  line-height: 200%;
}

.favourites-heading {
  font-size: 2em;
  text-align: center;
}

.share-fav-again {
  float: left;
  width: 100%;
}

.share-fav-again h2 {
  margin-top: 24px;
}

.share-fav-again input {
  width: 100%;
}

.heading-onesixth {
  display: inline-block;
  text-align: center;
  font-size: 0.8em;
  font-weight: normal;
  margin: 0.8em 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.cat,
.item,
.shop {
  position: relative;
  text-align: center;
}

.cat img:hover,
.item img:hover,
.shop img:hover {
  opacity: .9;
}

.item-image-container {
  display: inline-block;
  height: 180px;
  line-height: 180px;
  position: relative;
  text-align: center;
}

.item-image-container img {
  vertical-align: middle;
  line-height: 1;
}

.shop-location {
  color: #2b2b2b;
  display: inline-block;
  font-size: .85em;
  font-weight: bold;
  margin-top: .5em;
  text-transform: uppercase;
}

.shop-location:hover {
  color: black;
}

.shop-location:before {
  content: '\25BC';
  color: #57c7b3;
  margin-right: .15em;
}

.icon {
  background-size: 21px;
}

.icon-magnifier {
  background-image: url(../../gfx/search.svg);
}

.no-svg .icon-magnifier {
  background-image: url(../../gfx/search.png);
}

.icon-heart {
  background-image: url(../../gfx/favourite.svg);
  background-size: 20px;
}

.no-svg .icon-heart {
  background-image: url(../../gfx/favourite.png);
}

.missing-wrapper {
  text-align: center;
  margin-top: 4.8em;
  margin-bottom: 4.8em;
}

.item-sale-percentage {
  font-size: 1.2em;
  top: 1em;
  right: 1em;
}

.item-meta {
  padding: 24px;
}

.item-meta.faved .btn-fav {
  background-image: url(../../gfx/favourite-active.svg);
  border: 1px solid #FD9259;
  color: #FD9259;
}

.item-meta.faved .btn-fav:hover {
  opacity: .7;
}

.item-buttons {
  margin: 1em 0;
}

.item-buttons .btn {
  margin-bottom: 1em;
  margin-right: 1em;
}

.item-related h2,
.item-related h3 {
  padding: 0;
  margin: 0 0 12px 0;
  text-transform: none;
  text-align: center;
}

.information-content {
  float: left;
  width: 644px;
  margin-left: 12px;
}

.information-content h1 {
  font-size: 2em;
  text-transform: none;
  margin-top: 0;
}

.information-content h1 + p {
  margin-top: 0.75em;
}

.information-content h3 {
  font-size: 1.5em;
  margin-top: 0;
  margin-bottom: 0.3em;
}

.information-content h3 + p {
  margin-top: 0.75em;
}

.information-content p {
  margin-bottom: 1.5em;
  color: #777777;
}

.information-content.privacy-policy ul {
  list-style: disc;
  margin-left: 18px;
  margin-top: 0.75em;
  margin-bottom: 1.5em;
  color: #777777;
  font-size: 16px;
  font-family: "proxima soft", "Helvetica Rounded", sans-serif;
  line-height: 180%;
}

.information-sidebar {
  float: left;
  width: 330px;
  margin-left: 24px;
}

.information-facebook-comments .fb-comments,
.information-facebook .fb-like-box {
  padding: 12px;
}

.information-about-image {
  margin-bottom: 24px;
}

.admin-sorting {
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  float: left;
  left: 24px;
  padding: 12px;
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 9999;
}

.admin-sorting select {
  background: #fcfcfc;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  display: block;
  margin-bottom: 12px;
  outline: 0;
  padding: .15em .5em;
  width: 100%;
}

.admin-sorting button,
.admin-sorting hr {
  margin: 12px 0;
}

.admin-sorting label {
  color: #777777;
  font-size: 0.85em;
}

.checked {
  box-shadow: 0 0 5px 0 #FD9259;
}

.sidebar-newsletter {
  text-align: center;
}

.sidebar-newsletter h3 {
  border: 0;
}

.sidebar-newsletter p {
  margin: 20px;
}

.sidebar-newsletter form {
  font-size: 0.8em;
}

.modal-wrapper-newsletter {
  padding: 4em 2em;
  width: 500px;
}

.modal-wrapper-newsletter p {
  margin: 0 0 2em 0;
}

.cat h3 {
  height: 24px;
  padding: 0;
  margin: 6px 0 0 0;
  font-size: 20px;
}

.shop-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  float: left;
  margin-left: 12px;
}

.shop-info {
  border-left: 1px solid #d9d9d9;
  float: left;
  min-height: 500px;
  padding: 24px;
  width: 646px;
}

.shop-meta {
  float: left;
  padding: 24px 0;
  width: 350px;
}

.shop-meta .sidebar-box, .shop-meta .filter-content {
  margin: 1em 20px;
}

.shop-meta img {
  width: auto;
}

.shop-meta .btn {
  margin: 24px 0;
}

.messagePage-wrapper {
  width: 50%;
  padding: 1em;
  margin: 0 auto;
  text-align: center;
}

.messagePage-wrapper .messagePage-image {
  width: auto;
}

.messagePage-wrapper h1,
.messagePage-wrapper h2,
.messagePage-wrapper h3 {
  margin-top: 15px;
}

.messagePage-wrapper form {
  margin-top: 2em;
}

.pagination {
  margin: 0;
  text-align: right;
  float: right;
}

.pagination li {
  display: inline-block;
  margin-top: 5px;
}

.pagination li:not(:last-child) {
  margin-right: 5px;
}

.pagination-tally {
  color: #2b2b2b;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  margin-right: 1em;
  margin-top: 8px;
}

.home-header {
  margin-left: 12px;
}

.home-header > a {
  float: left;
  font-size: 0;
  line-height: 0;
}

.home-header > a:hover {
  opacity: 1;
}

.home-header :nth-of-type(2),
.home-header :nth-of-type(4) {
  margin: 0 0 0 2px;
}

.home-header :nth-of-type(3) {
  margin: 0 0 2px 2px;
}

.home-header img {
  max-width: 100%;
}

.home-shops {
  margin: 12px 0 0 12px;
}

.home-explanation {
  background: white;
  margin-left: 12px;
  margin-bottom: 24px;
  border-radius: 3px;
}

.home-explanation section {
  width: 25%;
  float: left;
  padding: 1em;
  text-align: center;
}

.home-brands {
  text-align: center;
  margin-top: 48px;
  margin-bottom: 24px;
}

.home-brands a {
  margin: 0 12px;
}

.brands-hr {
  margin: 24px 0 0 12px;
  opacity: 0.7;
}

.info-hr {
  margin: 12px 0 12px 12px;
  padding: 4px;
  border-radius: 3px;
  background-color: #57c7b3;
}

.info-hr p,
.info-hr ol,
.info-hr h1 {
  padding: 1em;
  margin: 0;
  font-size: 1.15em;
  font-weight: bold;
  font-style: italic;
  border: 1px solid white;
  color: white;
  text-align: center;
  text-shadow: 1px 1px 0px #777;
}

.info-hr p li,
.info-hr ol li,
.info-hr h1 li {
  background-repeat: no-repeat;
  background-image: url(../../gfx/bullet-icon.png);
  background-position: center left;
  display: inline-block;
  padding: 0.2em 0 0.2em 2em;
}

.info-hr p li + li,
.info-hr ol li + li,
.info-hr h1 li + li {
  margin-left: 3em;
}

.info-hr.black-friday {
  background-color: #000000;
}

.info-hr.black-friday p,
.info-hr.black-friday ol,
.info-hr.black-friday h1 {
  color: #f74f4e;
  border-color: #f74f4e;
  text-shadow: none;
}

.info-hr-list {
  background-color: #FD9259;
}

.info-hr-list ol li {
  text-shadow: 1px 1px 0px #b36114;
}

.home-info {
  margin-left: 12px;
}

.home-info .home-info-half {
  float: left;
  margin: 0 2%;
  text-align: center;
  width: 46%;
}

.home-info p {
  font-size: 0.85em;
}

.contest-wrapper .contest-p {
  font-weight: bold;
  color: #2b2b2b;
}

.contest-wrapper h2 {
  line-height: 2em;
  vertical-align: middle;
  font-size: 2em;
  font-weight: normal;
}

.contest-wrapper i {
  border-radius: 50%;
  color: white;
  background: #FD9259;
  text-align: center;
  width: 2em;
  height: 2em;
  display: inline-block;
  font-weight: bold;
  text-shadow: 1px 1px 1px #ad5a00;
}

.contest-wrapper .contest-icon-teal {
  background: #57c7b3;
  font-style: normal;
  line-height: 1.9em;
  text-shadow: 1px 1px 1px #00977f;
}

.contest-wrapper .contest-icon-blue {
  background: #406889;
  text-shadow: 1px 1px 1px #00356f;
}

.contest-wrapper ul,
.contest-wrapper > p,
.contest-wrapper dl,
.contest-wrapper .fb-like-box {
  padding-left: 4.7em;
}

.contest-wrapper label {
  margin-bottom: 0.5em;
  display: block;
}

.contest-wrapper input {
  display: block;
  width: 80%;
  border-radius: 3px;
}

.contest-wrapper .price-sale {
  padding: 0;
}

.contest-wrapper .form-group {
  margin-bottom: 1em;
}

.contest-wrapper form {
  margin-left: 4.7em;
}

.contest-wrapper dt {
  font-size: 1.5em;
  margin-left: 0;
  display: inline;
  font-weight: bold;
  line-height: 2em;
  vertical-align: middle;
}

.contest-wrapper dd {
  display: inline;
  font-size: 1.5em;
  margin-left: 1em;
}

.contest-wrapper dd:after {
  content: '';
  display: block;
}

.contest-wrapper .contest-prize {
  color: #57c7b3;
  font-weight: bold;
}

.contest-wrapper .contest-prize-1 {
  font-size: 1.5em;
}

.contest-wrapper .contest-prize-2 {
  font-size: 1.3em;
}

.contest-wrapper .contest-prize-3 {
  font-size: 1.1em;
}

.contest-wrapper .contest-confirm {
  background: #57c7b3;
  color: white;
  padding: 1em;
  font-weight: bold;
  font-size: 1.15em;
  border-radius: 3px;
  text-align: center;
}

@media all and (max-width: 1040px) {
  .onehalf {
    background-color: red !important;
    box-shadow: 0 0 2em green !important;
    color: blue !important;
    width: 100%;
    margin: 0;
  }
  .onehalf * {
    background-color: red !important;
    box-shadow: 0 0 2em green !important;
    color: blue !important;
  }
}

@media all and (max-width: 830px) {
  .onehalf {
    width: 100%;
    margin: 0;
  }
  .contest-wrapper {
    margin: 0 2%;
  }
  .contest-wrapper h2 {
    font-size: 1.5em;
  }
  .contest-wrapper ul,
  .contest-wrapper p,
  .contest-wrapper dl,
  .contest-wrapper .fb-like-box {
    padding-left: 0;
  }
  .contest-wrapper form {
    margin-left: 0;
  }
  .contest-wrapper dd {
    font-size: 1em;
  }
}

.influencer {
  min-height: 240px;
  width: 504px;
  margin-bottom: 12px;
  float: right;
}

.influencer-item {
  float: left;
  margin: 0 0 12px 12px;
  overflow: hidden;
  width: 190px;
}

.influencer-image-container {
  display: inline-block;
  float: left;
  line-height: 0;
  overflow: hidden;
  position: relative;
  height: 188px;
}

.influencer-image-container > span {
  line-height: 188px;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.influencer-image-container > span > img {
  vertical-align: middle;
  height: 3em;
  opacity: .5;
  width: 3em;
}

.influencer-image-container > img {
  height: auto;
  width: 100%;
}

.influencer-image-container:hover > span {
  background: rgba(0, 0, 0, 0.2);
}

.influencer-image-container:hover > span > img {
  opacity: .3;
}

.influencer-attribution {
  white-space: nowrap;
  padding: 0.4em;
  float: left;
}

.influencer-attribution img {
  width: 2em;
  height: 2em;
  vertical-align: middle;
  margin-right: .5em;
}

.influencer-profile-image {
  border-radius: 50%;
  border: 1px solid #d9d9d9;
}

.influencer-modal-image {
  float: left;
  line-height: 0;
  text-align: center;
  width: 60%;
}

.influencer-modal-image img {
  max-width: 100%;
  height: auto;
}

.influencer-modal-content {
  float: left;
  width: 40%;
  padding-top: 2em;
  padding-bottom: 0;
}

.influencer-modal-content h3 {
  color: #d9d9d9;
  font-size: .8em;
  letter-spacing: .2em;
  margin-top: 2em;
  text-align: center;
  text-transform: uppercase;
}

.influencer-modal-content .item {
  float: none;
  margin: 2em auto 0 auto;
}

.influencer-modal-attribution {
  text-align: center;
}

.influencer-modal-attribution img {
  width: 8em;
  height: 8em;
}

.influencer-modal-attribution p {
  margin: 0;
}

.influencer-modal-attribution h2 {
  margin: 1em 0 0 0;
  font-size: 1.2em;
  font-weight: normal;
}

.influencer-modal-attribution a {
  color: #125688;
  font-weight: bold;
}

.influencer-modal-attribution a:hover {
  color: #1872b5;
}

@media all and (max-width: 830px) {
  .influencer {
    width: 50%;
    margin-left: 0;
  }
  .influencer-item {
    width: 46%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .influencer-item:nth-child(even) {
    margin-left: 2%;
  }
  .influencer-modal-image,
  .influencer-modal-content {
    width: 100%;
  }
  .influencer-modal-image .onesixth,
  .influencer-modal-content .onesixth {
    width: 100%;
  }
}

@media all and (max-width: 667px) {
  .influencer {
    width: 66.666666%;
  }
}

@media all and (max-width: 567px) {
  .influencer {
    width: 100%;
    margin-left: 0;
  }
}

.smuts-dirty {
  border: 1px solid #57c7b3;
}

.noUiSlider {
  margin: 20px 24px 30px 24px;
  border: 0;
  background: none;
  box-shadow: none;
}

.noUiSlider * {
  box-shadow: none;
}

.noUi-background {
  background: #fcfcfc;
}

.noUi-origin {
  border-radius: 0;
}

.noUi-origin:first-of-type {
  background: #ff685c;
}

.noUi-origin:last-of-type {
  background: #fcfcfc;
}

.noUi-handle {
  border: 5px solid white;
  box-shadow: 0 0 0 1px #ff685c;
  background-color: #ff685c;
  cursor: pointer;
}

.noUi-horizontal {
  height: 6px;
}

.noUi-horizontal .noUi-handle {
  width: 16px;
  height: 16px;
  left: -8px;
  top: -5px;
  border-radius: 50%;
}

.noUi-horizontal .noUi-handle:before, .noUi-horizontal .noUi-handle:after {
  content: none;
}

#nprogress .bar,
#nprogress .spinner,
#nprogress .peg,
#nprogress .spinner-icon {
  z-index: 99999;
}

#nprogress .bar {
  background: #FD9259;
}

#nprogress .peg {
  box-shadow: 0 0 10px #FD9259, 0 0 5px #FD9259;
}

#nprogress .spinner {
  top: auto;
  bottom: 15px;
  display: none;
}

#nprogress .spinner-icon {
  border-top-color: #FD9259;
  border-left-color: #FD9259;
}

.fm-orange-container {
  background: #FD9259;
  border: 1px solid #fc7127;
  color: white;
}

.fm-orange {
  color: #FD9259;
}

.fm-orange-bg {
  background: #FD9259;
}

.fm-orange-banner {
  background: #FD9259 !important;
  border-bottom: 1px solid #fc7127 !important;
  color: white;
}

.fm-orange-banner button,
.fm-orange-banner button:active,
.fm-orange-banner .btn,
.fm-orange-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-orange-banner button:hover,
.fm-orange-banner button:active:hover,
.fm-orange-banner .btn:hover,
.fm-orange-banner .btn:active:hover {
  color: #FD9259;
  background-color: white;
}

.fm-teal-container {
  background: #57c7b3;
  border: 1px solid #3bb09b;
  color: white;
}

.fm-teal {
  color: #57c7b3;
}

.fm-teal-bg {
  background: #57c7b3;
}

.fm-teal-banner {
  background: #57c7b3 !important;
  border-bottom: 1px solid #3bb09b !important;
  color: white;
}

.fm-teal-banner button,
.fm-teal-banner button:active,
.fm-teal-banner .btn,
.fm-teal-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-teal-banner button:hover,
.fm-teal-banner button:active:hover,
.fm-teal-banner .btn:hover,
.fm-teal-banner .btn:active:hover {
  color: #57c7b3;
  background-color: white;
}

.fm-red-container {
  background: #ff685c;
  border: 1px solid #ff3929;
  color: white;
}

.fm-red {
  color: #ff685c;
}

.fm-red-bg {
  background: #ff685c;
}

.fm-red-banner {
  background: #ff685c !important;
  border-bottom: 1px solid #ff3929 !important;
  color: white;
}

.fm-red-banner button,
.fm-red-banner button:active,
.fm-red-banner .btn,
.fm-red-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-red-banner button:hover,
.fm-red-banner button:active:hover,
.fm-red-banner .btn:hover,
.fm-red-banner .btn:active:hover {
  color: #ff685c;
  background-color: white;
}

.fm-black-container {
  background: #2b2b2b;
  border: 1px solid #121111;
  color: white;
}

.fm-black {
  color: #2b2b2b;
}

.fm-black-bg {
  background: #2b2b2b;
}

.fm-black-banner {
  background: #2b2b2b !important;
  border-bottom: 1px solid #121111 !important;
  color: white;
}

.fm-black-banner button,
.fm-black-banner button:active,
.fm-black-banner .btn,
.fm-black-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-black-banner button:hover,
.fm-black-banner button:active:hover,
.fm-black-banner .btn:hover,
.fm-black-banner .btn:active:hover {
  color: #2b2b2b;
  background-color: white;
}

.fm-spartoo-container {
  background: #bc0044;
  border: 1px solid #890032;
  color: white;
}

.fm-spartoo {
  color: #bc0044;
}

.fm-spartoo-bg {
  background: #bc0044;
}

.fm-spartoo-banner {
  background: #bc0044 !important;
  border-bottom: 1px solid #890032 !important;
  color: white;
}

.fm-spartoo-banner button,
.fm-spartoo-banner button:active,
.fm-spartoo-banner .btn,
.fm-spartoo-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-spartoo-banner button:hover,
.fm-spartoo-banner button:active:hover,
.fm-spartoo-banner .btn:hover,
.fm-spartoo-banner .btn:active:hover {
  color: #bc0044;
  background-color: white;
}

.fm-footway-container {
  background: #643287;
  border: 1px solid #482462;
  color: white;
}

.fm-footway {
  color: #643287;
}

.fm-footway-bg {
  background: #643287;
}

.fm-footway-banner {
  background: #643287 !important;
  border-bottom: 1px solid #482462 !important;
  color: white;
}

.fm-footway-banner button,
.fm-footway-banner button:active,
.fm-footway-banner .btn,
.fm-footway-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-footway-banner button:hover,
.fm-footway-banner button:active:hover,
.fm-footway-banner .btn:hover,
.fm-footway-banner .btn:active:hover {
  color: #643287;
  background-color: white;
}

.fm-deichmann-container {
  background: #249760;
  border: 1px solid #1a6e46;
  color: white;
}

.fm-deichmann {
  color: #249760;
}

.fm-deichmann-bg {
  background: #249760;
}

.fm-deichmann-banner {
  background: #249760 !important;
  border-bottom: 1px solid #1a6e46 !important;
  color: white;
}

.fm-deichmann-banner button,
.fm-deichmann-banner button:active,
.fm-deichmann-banner .btn,
.fm-deichmann-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-deichmann-banner button:hover,
.fm-deichmann-banner button:active:hover,
.fm-deichmann-banner .btn:hover,
.fm-deichmann-banner .btn:active:hover {
  color: #249760;
  background-color: white;
}

.fm-sarenza-container {
  background: #ec1082;
  border: 1px solid #bc0d68;
  color: white;
}

.fm-sarenza {
  color: #ec1082;
}

.fm-sarenza-bg {
  background: #ec1082;
}

.fm-sarenza-banner {
  background: #ec1082 !important;
  border-bottom: 1px solid #bc0d68 !important;
  color: white;
}

.fm-sarenza-banner button,
.fm-sarenza-banner button:active,
.fm-sarenza-banner .btn,
.fm-sarenza-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-sarenza-banner button:hover,
.fm-sarenza-banner button:active:hover,
.fm-sarenza-banner .btn:hover,
.fm-sarenza-banner .btn:active:hover {
  color: #ec1082;
  background-color: white;
}

.fm-bonprix-container {
  background: #cb1030;
  border: 1px solid #9c0c25;
  color: white;
}

.fm-bonprix {
  color: #cb1030;
}

.fm-bonprix-bg {
  background: #cb1030;
}

.fm-bonprix-banner {
  background: #cb1030 !important;
  border-bottom: 1px solid #9c0c25 !important;
  color: white;
}

.fm-bonprix-banner button,
.fm-bonprix-banner button:active,
.fm-bonprix-banner .btn,
.fm-bonprix-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-bonprix-banner button:hover,
.fm-bonprix-banner button:active:hover,
.fm-bonprix-banner .btn:hover,
.fm-bonprix-banner .btn:active:hover {
  color: #cb1030;
  background-color: white;
}

.fm-conleys-container {
  background: #f5ba14;
  border: 1px solid #cd9a09;
  color: white;
}

.fm-conleys {
  color: #f5ba14;
}

.fm-conleys-bg {
  background: #f5ba14;
}

.fm-conleys-banner {
  background: #f5ba14 !important;
  border-bottom: 1px solid #cd9a09 !important;
  color: white;
}

.fm-conleys-banner button,
.fm-conleys-banner button:active,
.fm-conleys-banner .btn,
.fm-conleys-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-conleys-banner button:hover,
.fm-conleys-banner button:active:hover,
.fm-conleys-banner .btn:hover,
.fm-conleys-banner .btn:active:hover {
  color: #f5ba14;
  background-color: white;
}

.fm-gray-container {
  background: #777777;
  border: 1px solid #5e5d5d;
  color: white;
}

.fm-gray {
  color: #777777;
}

.fm-gray-bg {
  background: #777777;
}

.fm-gray-banner {
  background: #777777 !important;
  border-bottom: 1px solid #5e5d5d !important;
  color: white;
}

.fm-gray-banner button,
.fm-gray-banner button:active,
.fm-gray-banner .btn,
.fm-gray-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-gray-banner button:hover,
.fm-gray-banner button:active:hover,
.fm-gray-banner .btn:hover,
.fm-gray-banner .btn:active:hover {
  color: #777777;
  background-color: white;
}

.fm-brown-container {
  background: #916b14;
  border: 1px solid #644a0e;
  color: white;
}

.fm-brown {
  color: #916b14;
}

.fm-brown-bg {
  background: #916b14;
}

.fm-brown-banner {
  background: #916b14 !important;
  border-bottom: 1px solid #644a0e !important;
  color: white;
}

.fm-brown-banner button,
.fm-brown-banner button:active,
.fm-brown-banner .btn,
.fm-brown-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-brown-banner button:hover,
.fm-brown-banner button:active:hover,
.fm-brown-banner .btn:hover,
.fm-brown-banner .btn:active:hover {
  color: #916b14;
  background-color: white;
}

.fm-yellow-container {
  background: #fcd932;
  border: 1px solid #f7cd04;
  color: white;
}

.fm-yellow {
  color: #fcd932;
}

.fm-yellow-bg {
  background: #fcd932;
}

.fm-yellow-banner {
  background: #fcd932 !important;
  border-bottom: 1px solid #f7cd04 !important;
  color: white;
}

.fm-yellow-banner button,
.fm-yellow-banner button:active,
.fm-yellow-banner .btn,
.fm-yellow-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-yellow-banner button:hover,
.fm-yellow-banner button:active:hover,
.fm-yellow-banner .btn:hover,
.fm-yellow-banner .btn:active:hover {
  color: #fcd932;
  background-color: white;
}

.fm-green-container {
  background: #00c166;
  border: 1px solid #008e4b;
  color: white;
}

.fm-green {
  color: #00c166;
}

.fm-green-bg {
  background: #00c166;
}

.fm-green-banner {
  background: #00c166 !important;
  border-bottom: 1px solid #008e4b !important;
  color: white;
}

.fm-green-banner button,
.fm-green-banner button:active,
.fm-green-banner .btn,
.fm-green-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-green-banner button:hover,
.fm-green-banner button:active:hover,
.fm-green-banner .btn:hover,
.fm-green-banner .btn:active:hover {
  color: #00c166;
  background-color: white;
}

.fm-blue-container {
  background: #3b93d8;
  border: 1px solid #2579bb;
  color: white;
}

.fm-blue {
  color: #3b93d8;
}

.fm-blue-bg {
  background: #3b93d8;
}

.fm-blue-banner {
  background: #3b93d8 !important;
  border-bottom: 1px solid #2579bb !important;
  color: white;
}

.fm-blue-banner button,
.fm-blue-banner button:active,
.fm-blue-banner .btn,
.fm-blue-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-blue-banner button:hover,
.fm-blue-banner button:active:hover,
.fm-blue-banner .btn:hover,
.fm-blue-banner .btn:active:hover {
  color: #3b93d8;
  background-color: white;
}

.fm-pink-container {
  background: #ff19ce;
  border: 1px solid #e500b4;
  color: white;
}

.fm-pink {
  color: #ff19ce;
}

.fm-pink-bg {
  background: #ff19ce;
}

.fm-pink-banner {
  background: #ff19ce !important;
  border-bottom: 1px solid #e500b4 !important;
  color: white;
}

.fm-pink-banner button,
.fm-pink-banner button:active,
.fm-pink-banner .btn,
.fm-pink-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-pink-banner button:hover,
.fm-pink-banner button:active:hover,
.fm-pink-banner .btn:hover,
.fm-pink-banner .btn:active:hover {
  color: #ff19ce;
  background-color: white;
}

.fm-purple-container {
  background: #b500a1;
  border: 1px solid #820074;
  color: white;
}

.fm-purple {
  color: #b500a1;
}

.fm-purple-bg {
  background: #b500a1;
}

.fm-purple-banner {
  background: #b500a1 !important;
  border-bottom: 1px solid #820074 !important;
  color: white;
}

.fm-purple-banner button,
.fm-purple-banner button:active,
.fm-purple-banner .btn,
.fm-purple-banner .btn:active {
  color: white;
  background-color: transparent;
  border-color: white;
}

.fm-purple-banner button:hover,
.fm-purple-banner button:active:hover,
.fm-purple-banner .btn:hover,
.fm-purple-banner .btn:active:hover {
  color: #b500a1;
  background-color: white;
}

.box > .btn-alert, .items-footer > .btn-alert, .items-heading > .btn-alert {
  float: right;
  margin-bottom: 0.8em;
}

.box, .items-footer,
.items-heading {
  width: 998px;
  margin-left: 12px;
  margin-bottom: 24px;
}

.box::after, .items-footer::after,
.items-heading::after {
  clear: both;
  content: "";
  display: table;
}

.box-border {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}

.items-heading {
  position: relative;
  padding: 22px 12px;
  width: 100%;
}

.items-heading > h1,
.items-heading > h2,
.items-heading > p {
  text-align: center;
}

.items-heading > h1 {
  display: inline-block;
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
}

.items-heading > p {
  font-size: 0.8em;
}

.items-heading .items-heading-sorting {
  float: left;
}

.items-heading .items-heading-sorting {
  width: 45%;
}

.items-num {
  font-style: italic;
}

.items-heading .items-num {
  padding-top: .6em;
  margin: 0;
  padding-left: 3%;
}

.items-heading-text-toggle {
  margin-bottom: 1em;
  font-weight: bold;
  text-align: center;
  color: #2b2b2b;
  display: none;
}

.items-heading-text-toggle:hover {
  color: #2b2b2b;
}

.items-heading-footer {
  margin-top: 25px;
}

.missing-wrapper {
  text-align: center;
  margin-top: 4.8em;
  margin-bottom: 4.8em;
}

.missing-wrapper img {
  width: auto;
}

.newsletter-form .btn {
  position: absolute;
  top: 0px;
  right: 0px;
}

@media all and (max-width: 1250px) {
  .banner-loose .header-logo {
    display: none;
  }
  .banner-loose #main-nav {
    display: none;
  }
  .wrapper-row {
    width: 1025px;
  }
  .row-items,
  .information-content {
    width: 808px;
  }
  .box, .items-footer,
  .items-heading {
    width: 796px;
  }
  .header-logo {
    width: 100%;
    text-align: center;
  }
  .header-favs {
    width: 20%;
  }
  .header-widgets {
    margin: 0;
    width: 80%;
  }
  #main-nav {
    text-align: center;
    padding: 0;
    width: 100%;
  }
  #sorting span {
    display: none;
  }
  .home-header a:nth-of-type(1), .home-header a:nth-of-type(2) {
    margin: 0;
  }
  .home-header a:nth-of-type(3), .home-header a:nth-of-type(4) {
    display: none;
  }
  .home-header img {
    height: auto;
    width: 100%;
  }
  .home-shops {
    display: none;
  }
  .shop-info {
    width: 444px;
  }
  .filter-search-tags {
    max-width: 70%;
  }
}

@media all and (max-width: 1040px) {
  .wrapper-row {
    width: 823px;
  }
  .row-items,
  .information-content {
    width: 606px;
  }
  .box, .items-footer,
  .items-heading {
    width: 594px;
  }
  .items-heading .items-heading-sorting {
    width: 30%;
  }
  .items-heading .pagination {
    width: 70%;
  }
  .filter-per-page {
    display: none;
  }
  .pagination-tally {
    display: inline-block;
    text-align: center;
  }
  .pagination-tally-page {
    display: none;
  }
  .modal-wrapper-item {
    width: 823px;
  }
  .home-explanation section {
    width: 50%;
  }
  .banner-help-you {
    display: none;
  }
  .shop-info {
    width: 100%;
    min-height: 0;
    float: left;
    border: 0;
  }
  .shop-meta {
    width: 100%;
    float: left;
    padding: 40px 20px 40px 20px;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 24px;
  }
}

@media all and (max-width: 829px) {
  html {
    overflow-x: hidden;
  }
  body {
    overflow-x: hidden;
  }
  .modal-wrapper-item {
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
  .modal-wrapper-item .item-meta-list {
    margin-bottom: 0;
  }
  .modal-wrapper-item .item-meta h2 {
    text-align: center;
  }
  .modal-wrapper-item .item-half {
    width: 100%;
  }
  .modal-wrapper-item .item-half:first-of-type {
    width: 100%;
  }
  .modal-wrapper-item .item-desc {
    display: none;
  }
  .modal-wrapper-item .item-price {
    text-align: center;
    margin-top: 0;
  }
  .item-buttons {
    text-align: center;
  }
  .item-buttons .btn-buy {
    background-image: none;
    padding-left: 1.5em;
    margin-right: 0;
    width: 100%;
    text-align: center;
  }
  .item-buttons .btn-fav {
    margin-right: 1%;
  }
  .item-buttons .btn-alert {
    margin-left: 1%;
    margin-right: 0;
  }
  .item-buttons .btn-fav,
  .item-buttons .btn-alert {
    width: 49%;
  }
  .header-favs {
    position: absolute;
    top: 1em;
    right: 12px;
    margin: 0;
    width: auto;
  }
  .banner-loose #main-nav {
    display: none;
  }
  .banner-main {
    padding: 0;
  }
  .banner-main .wrapper-row {
    margin-left: 0;
  }
  .breadcrumb-wrapper {
    text-align: center;
    background-color: #fcfcfc;
  }
  .wrapper-row {
    width: 100%;
  }
  .row-items {
    width: 100%;
  }
  .sidebar-main:not(.slide-menu) {
    width: 100%;
    text-align: center;
  }
  .sidebar {
    border-radius: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    float: none;
  }
  .sidebar h3 {
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 1em;
  }
  .sidebar h3 + ul,
  .sidebar h3 + div,
  .sidebar h3 + h4,
  .sidebar h3 + .filter-widget-body {
    margin-top: 0;
    margin-left: 15px;
    margin-right: 15px;
  }
  .sidebar h3 a {
    display: inline;
    font-size: 0.8em;
  }
  .sidebar-box-input {
    margin-top: 0;
  }
  .sidebar-newsletter {
    display: none;
  }
  .onesixth {
    width: 21%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .item-image-container {
    max-width: 100%;
    width: auto;
  }
  .item-meta {
    padding: 1em;
  }
  .item-breadcrumb-wrapper {
    margin-bottom: 1em;
    text-align: center;
  }
  .banner nav {
    float: none;
    text-align: center;
  }
  .banner-info {
    font-size: 1em;
  }
  .banner-info .wrapper-row p {
    display: none;
  }
  .logo {
    width: auto;
  }
  .header-logo {
    background: url(../../gfx/list.svg) no-repeat 1.2em center;
    background-size: 1.6em;
    font-size: 1.2em;
    width: 100%;
    text-align: center;
    margin: 0;
    float: none;
    padding: .57em 0;
  }
  .header-logo img {
    height: 2em;
  }
  .no-svg .header-logo {
    background-image: url(../../gfx/list.png);
    background-size: auto;
  }
  .home-info {
    margin-left: 0;
  }
  .info-hr {
    margin-left: 0;
    border-radius: 0;
  }
  #main-nav {
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
    float: none;
    padding: 12px 0;
    display: none;
    font-size: 0.8em;
  }
  #main-nav > ul > li {
    margin-right: 0em;
    margin-bottom: 0.5em;
    padding: 0;
  }
  #main-nav .main-nav-soon {
    display: none;
  }
  .items-heading-sorting {
    display: none;
  }
  .banner-main .wrapper-row {
    position: relative;
  }
  .banner-counters {
    display: none;
  }
  .header-widgets {
    width: 100%;
    margin: 0;
  }
  .header-widgets .main-search {
    font-size: 16px;
    width: 100%;
    border: 0;
    text-align: left;
    background-color: transparent;
  }
  .filter-content.filter-main-search {
    border-left: none;
    border-right: none;
    padding: 2%;
    text-align: left;
  }
  #filter-container {
    border-top: 0;
    display: none;
  }
  #filter-container.filter-container-ready {
    display: block;
  }
  #filter-container h3 {
    font-size: 1.2em;
    padding-left: 15px;
  }
  #filter-container h3:not(.breadcrumb-title) {
    background: url(../../gfx/arrow-down.svg) right 1em center no-repeat;
    background-size: 1.6em;
    cursor: pointer;
    padding-right: 55px;
  }
  #filter-container h3.filter-show {
    background-image: url(../../gfx/arrow-up.svg);
  }
  #filter-container h3.filter-show + * {
    display: block;
  }
  .box, .items-footer,
  .items-heading {
    width: 100%;
    margin: 0;
    border: 0;
  }
  .items-heading {
    text-align: center;
    padding-top: 0;
  }
  .items-heading .pagination {
    width: 100%;
  }
  .items-heading .pagination li:not(.pagination-tally) {
    display: none;
  }
  .items-heading h1 {
    margin-top: 0;
  }
  .items-heading .items-heading-text-hide {
    max-height: 3.5em;
    overflow: hidden;
    margin-bottom: .5em;
  }
  .items-heading .items-heading-text-toggle {
    display: block;
  }
  .items-footer {
    margin: 2em 0;
  }
  .items-footer .pagination-tally {
    margin-bottom: 1em;
    margin-top: 2em;
  }
  .filter-search-tags {
    max-width: none;
    padding: 12px;
  }
  .box > .btn-alert, .items-footer > .btn-alert, .items-heading > .btn-alert {
    float: none;
  }
  .pagination {
    text-align: center;
    float: none;
  }
  .pagination .pagination-tally {
    display: block;
    margin-left: 0;
  }
  .items-tail {
    border-top: 1px solid #dbdbdb;
    border-bottom: 0;
  }
  footer h2 {
    font-size: 1.2em;
  }
  .footer-text {
    width: 100%;
    padding-right: 1em;
    padding-left: 1em;
  }
  .footer-small {
    display: none;
  }
  .information-header {
    font-size: .85em;
    margin-left: 0;
  }
  .information-header section {
    padding: 1%;
  }
  .item-image-wrapper {
    min-height: 0;
    margin-bottom: 0;
  }
  .item-image-wrapper img {
    max-height: 250px;
  }
  .up-btn-wrapper {
    display: none;
  }
  .info-hr ol li + li {
    margin: 0;
  }
  .home-header {
    margin-left: 0;
  }
  .info-nav {
    display: none;
  }
  .information-about-image {
    display: none;
  }
  .info-nav-toggle {
    display: block;
  }
  .footer-wrapper {
    padding: 0 2%;
  }
  .brand-container {
    padding: 0 0 1em 0;
  }
  .brand-container h2 {
    display: block;
    float: none;
    padding: 0 0 0 2%;
  }
  .brand-container .brand-column {
    width: 48%;
    padding: 0 0 0 2%;
  }
  .brand-alphabet li {
    padding: .5em;
    display: inline-block;
  }
  .brand-alphabet li:hover {
    border: 0;
  }
  .brand-alphabet li a {
    padding: .5em;
  }
  .shop-wrapper {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    margin: 0;
  }
  .shop-meta {
    width: 100%;
    padding: 2%;
    margin: 0;
  }
  .information-sidebar {
    width: 100%;
    margin-left: 0;
  }
  .information-content {
    width: 100%;
    border-top: 1px solid #d9d9d9;
    margin: 0;
    padding: 2%;
  }
  .home-info .home-info-half {
    width: 96%;
  }
  .slide-menu-header {
    display: block;
  }
  .filter-color li a {
    width: 1.6em;
    height: 1.6em;
  }
  .filter-brand-input {
    font-size: 16px;
  }
  .fm-input-dropdown-open .main-search,
  .fm-input-dropdown-open .fm-dropdown-content {
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-radius: 0;
  }
  .messagePage-wrapper {
    width: 100%;
  }
}

@media all and (max-width: 667px) {
  .onesixth {
    width: 29.333333%;
  }
}

@media all and (max-width: 567px) {
  .onesixth {
    width: 46%;
  }
}

/*# sourceMappingURL=app.css.map */
